<template>
	<div class="card">
		<h2>About Us</h2>
		<p>Welcome to {{sitecfg.domainName}} to play free online games in your area. There are new free games
			every day, including action games, adventure games, board and card games, multiplayer games, puzzle games,
			racing games, skill games, sports games and more addictive games. Play the best online and mobile games. We
			choose characteristic shooting games, puzzle games, strategy games, war games and more! Join the world's
			best free online game site, and you can play thousands of 3D games, Unity games, webgl games, and the best
			mobile games from Google Play and App Store without downloading! In addition, we provide all classic and
			retro games, crosswords, driving games, girl games, bingo games, Pacman, Tetris, pinball, bricks and math
			games for kids and all families. Play our HTML5 games on all devices, mobile phones, tablets, computers,
			etc. Enjoy the most interesting game with your friends. </p>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	export default {
		computed: {
			...mapState({
				sitecfg: state => state.sitecfg.sitecfg
			})
		}
	}
</script>

<style scoped>
	.card {
	    -webkit-box-flex: 1;
	    -webkit-flex: 1;
	    -moz-box-flex: 1;
	    flex: 1
	}
</style>